.headerimage {
  height: var(--header-image-size);
  width: var(--header-image-size);
  border-radius: var(--radius);
  margin-right: 16px;
}

.content {
  padding: var(--page-padding);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-grow: 1;
}

@media screen and (max-width: 900px) {
  .header {
    justify-content: center;
    text-align: center;
    margin: 16px 0px;
  }

  .headerimage {
    margin-right: 8px;
  }
}

.cover {
  border-radius: var(--radius);
}
