.default-chart {
  position: relative;
  height: 700px;
  width: 100%;
}

.otext {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subtitle {
  color: var(--text-grey);
}

.play-button-holder .play-image {
  opacity: inherit;
}

.play-button-holder .play-button {
  display: none;
}

@media screen and (min-width: 960px) {
  .play-button-holder .play-image {
    opacity: inherit;
  }
  
  .play-button-holder .play-button {
    display: none !important;
  }
  
  .play-button-holder:hover .play-image {
    opacity: 0.4;
  }
  
  .play-button-holder:hover .play-button {
    display: inherit !important;
  }
}
