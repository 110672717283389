.radiogroup {
  display: flex;
  align-items: center;
}

.dialogcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}
