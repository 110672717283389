.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.twonumbers {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.twonumbers > :last-child {
  font-size: 0.8em;
  transform: translateY(-2px);
  margin-left: 8px;
}

.number {
  font-size: 1.6em;
}

.more {
  color: green;
  font-size: 0.8em;
}

.less {
  color: red;
  font-size: 0.8em;
}
