.headerimage {
  height: var(--header-image-size);
  width: var(--header-image-size);
  border-radius: var(--radius);
  margin-right: 16px;
}

.content {
  padding: var(--page-padding);
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-grow: 1;
}

@media screen and (max-width: 900px) {
  .header {
    justify-content: center;
    text-align: center;
    margin: 16px 0px;
  }

  .headerimage {
    margin-right: 8px;
  }
}

.chart {
  height: 300px;
}

.cardimg {
  height: 48px;
  width: 48px;
  border-radius: 6px;
}

.songslistened {
  font-size: 1.4em;
}

.ml {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ml:not(:last-child) {
  margin-bottom: 8px;
}

.mlrank {
  font-size: 1.4em;
  width: 48px;
}

.bestperiod {
  display: flex;
  flex-direction: column;
}

.bestperiod:not(:last-child) {
  margin-bottom: 16px;
}

.bestperiod > strong {
  font-size: 1.4em;
}

.bestperiod > span {
  color: var(--text-grey);
}
