.ranks {
  display: flex;
  align-items: center;
  height: 24px;
}

.rank {
  margin-right: 16px;
}

.before {
  color: green;
}

.after {
  color: red;
}

.actual {
  font-weight: bold;
  color: var(--text-on-light);
}

.loading {
  display: flex;
  align-items: center;
}

.loading > span {
  margin-left: 8px;
}
