.root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--page-padding);
}

.title {
  margin-bottom: 32px;
  font-size: 4em;
  text-align: center;
}

.welcome {
  margin-bottom: 16px;
  text-align: center;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: #1BD760;
  color: white;
  height: 40px;
  border-radius: 20px;
  padding: 0px 58px;
  font-weight: bold;
}

.rememberMe {
  margin-top: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.check {
  padding: 0px !important;
}
