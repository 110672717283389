.header {
  margin-bottom: 16px;
}

.cover {
  border-radius: var(--radius);
}

.names > :first-child {
  margin-bottom: 4px;
}
