.input {
  border: none;
  font-size: unset;
  outline: none;
  background-color: var(--secondary-dark);
  color: white;
  border-radius: var(--radius);
  padding: 8px;
  margin-bottom: 32px;
  width: 100%;
}

.input:hover {
  background-color: var(--secondary-dark-hover);
}

.popper {
  z-index: 10000;
}

.alert {
  display: block;
  text-align: center;
  padding: 16px 0;
}

.results {
  margin-top: 4px;
  padding: 4px;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: var(--radius) !important;
}

.result {
  padding: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  text-align: left;
  color: inherit;
  text-decoration: none;
  width: 100%;
  border-radius: var(--radius);
}

.result:hover {
  background: var(--hover);
}

.resultimage {
  height: 48px;
  width: 48px;
  border-radius: 6px;
  margin-right: 8px;
}

.section {
  position: relative;
  text-align: left;
  font-weight: bold;
  padding: 8px;
}

.section:not(:first-child) {
  margin-top: 16px;
  padding-top: 12px;
}

.section:not(:first-child)::before {
  content: "";
  background: var(--divider);
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
  height: 1px;
}
