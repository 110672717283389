.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.root a {
  text-decoration: none;
}
