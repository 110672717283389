.root {
  height: 60px;
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.text {
  margin-top: 8px;
}
