.accordion {
  border: 1px solid var(--hover);
}

.accordion:not(:first-of-type) {
  margin-top: -1px;
}

.line {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.line > :first-child {
  margin-right: 8px;
  width: 32px;
}

.artist {
  color: var(--text-grey);
}
