.root {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  margin: auto;
  text-align: center;
}

.explain {
  display: block;
  margin-top: 8px;
}

@media screen and (max-width: 960px) {
  .root {
    width: 95%;
  }
}
