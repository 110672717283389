.root {
  position: relative;
  height: 48px;
  width: 48px;
  overflow: hidden;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 48px;
  width: 48px;
}

.icon {
  color: var(--primary) !important;
}
