.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content {
  margin-top: 32px !important;
  width: 60%;
  margin: auto;
}

@media screen and (max-width: 1250px) {
  .content {
    width: 95%;
  }
}

.album {
  display: flex;
  flex-direction: row;
  padding: 8px;
  align-items: center;
}

.ranking {
  width: 32px;
  margin-right: 8px;
  flex-shrink: 0;
}

.albumimage {
  height: 48px;
  width: 48px;
  margin-right: 8px;
  border-radius: var(--radius);
}

.albumname {
  flex-grow: 1;
}

.artist {
  color: grey;
}

.enjoyed {
  flex-shrink: 0;
}
