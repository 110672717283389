body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--content-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-size: unset;
  font-size: 0.9em;
  padding: 2px;
  background-color: var(--code-background);
  border-radius: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

* {
  box-sizing: border-box;
}

:root {
  --track-options: 34px;
  --radius: 6px;
  --page-padding: 0 16px 16px 16px;
  --accent: #FB717B;
  --header-image-size: 60px;
  --secondary-dark: rgba(255, 255, 255, 0.15);
  --secondary-dark-hover: rgba(255, 255, 255, 0.25);
}

.dark-vars {
  --content-border: 1px solid rgb(51, 51, 52);
  --hardshadow: 3px 3px 40px -5px rgba(170, 170, 170, 0.437);

  --primary: white;

  --text-grey: rgb(90, 90, 90);
  --border-color: rgb(90, 90, 90);

  --hover: rgb(65, 65, 65);

  --background: black;
  --content-background: black;

  --text-on-light: white;
  --text-on-dark: white;
  --code-background: rgb(133, 133, 133);

  --divider: rgb(65, 65, 65);
}

.light-vars {
  --shadow: 5px 5px 8px -2px rgba(0,0,0,0.05);
  --hardshadow: 5px 6px 40px -5px rgba(0, 0, 0, 0.2);

  --primary: black;

  --text-grey: rgb(90, 90, 90);
  --border-color: rgb(227, 229, 233);

  --hover: #eaeaea;

  --background: white;
  --content-background: #f2f3f6;

  --text-on-light: black;
  --text-on-dark: white;
  --code-background: rgb(228, 228, 228);

  --divider: rgb(232, 232, 232);
}

@media (hover: none) and (pointer: coarse) {
  * {
    user-select: none;
  }
}

@media screen and (max-width: 960px) {
  :root {
    --page-padding: 0px 8px 8px 8px;
    --header-image-size: 40px;
  }
}

@keyframes fadein {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.fade {
  animation-name: fadein;
  animation-duration: 1000ms;
  animation-iteration-count: 1;
}

.no-button {
  cursor: pointer;
  border: none;
  outline: none;
  font-size: unset;
  color: unset;
  background: none;
  background-color: none;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
