.category {
  margin-bottom: 32px;
}

.categoryname {
  margin-bottom: 8px;
  margin-left: 8px;
  font-size: 0.9em;
  opacity: 0.8;
}

.link {
  display: flex;
  margin-bottom: 2px;
  padding: 8px;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.linkcontent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.linkcontent > :first-child {
  margin-right: 8px;
  font-size: 1.25em;
}

.link > span {
  margin-right: 8px;
}

.link:has(.active) {
    background-color: var(--secondary-dark);
}

.link:hover {
  background-color: var(--secondary-dark-hover);
}

.active {
  color: var(--accent) !important;
}
