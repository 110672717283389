.popper {
  padding: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--hardshadow);
  border-radius: 6px;
  z-index: 1;
  background-color: var(--background);
}

.destructive {
  color: rgb(242, 93, 93) !important;
  font-weight: normal;
}
