.disclaimer {
  margin-bottom: 16px;
}

.link {
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
