.row {
  height: 48px;  
}

.header {
  margin-bottom: 16px;
}

.cover {
  border-radius: var(--radius);
}